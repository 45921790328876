import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded';
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DateUtils } from '../../../utils/DateUtils';

const Widget = styled('div')(({ theme }) => ({
    padding: '0.5rem',
    borderRadius: 8,
    maxWidth: '100%',
    margin: 'auto',
    position: 'relative',
    height: 'var(--player-height)',
    maxHeight: 'var(--player-height)',
    zIndex: 1,
    backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.6)' : 'rgba(218, 223, 243)',
    backdropFilter: 'blur(40px)',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
        padding: 12,
        flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
        height: 'var(--player-height-sm)',
        maxHeight: 'var(--player-height-sm)',
    }
}));

const CoverImage = styled('div')(({ theme }) => ({
    maxWidth: 60,
    maxHeight: 60,
    aspectRatio: '1/1',
    objectFit: 'cover',
    overflow: 'hidden',
    flexShrink: 0,
    borderRadius: 8,
    backgroundColor: 'rgba(0,0,0,0.08)',
    flex: 1,
    '& > img': {
        width: '100%',
        height: '100%'
    },
    [theme.breakpoints.up('sm')]: {
        maxWidth: 100,
        maxHeight: 100,
    }
}));

const TinyText = styled(Typography)({
    fontSize: '0.75rem',
    opacity: 0.38,
    fontWeight: 500,
    letterSpacing: 0.2,
});
const ControlsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // mt: -1,
    flex: 2
}));

const ControlButtonsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: "3.2rem",
}));

const SliderContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
}));

const MetadataContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1
}));

const VolumeContainer = styled('div')(({ theme }) => ({
    display: 'none',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
        display: 'flex'
    }
}));

interface MusicPlayerProps {
    tracks: Track[];
    trackIndex: number;
    duration: number;
    isPlaying: boolean;
    position: number;
    volume: number;
    onDurationChange: (arg0: number) => void;
    onVolumeChange: (arg0: number) => void;
    onTrackChange: (arg0: number) => void;
    onPositionChange: (position: number) => void;
    onPlay: () => void;
    onPause: () => void;
    onTrackEnd?: () => void;
}

const MusicPlayer: FC<MusicPlayerProps> = (props: MusicPlayerProps) => {
    const {
        tracks,
        trackIndex,
        duration,
        isPlaying,
        position,
        volume,
        onDurationChange, onVolumeChange, onTrackChange, onPositionChange, onPlay, onPause, onTrackEnd
    } = props;

    const theme = useTheme();

    const audioRef = useRef<HTMLAudioElement>(null);
    const playAnimationRef = useRef<number>();

    const [paused, setPaused] = useState(true);
    const [pos, setPos] = useState<number>(0);

    const mainIconColor = theme.palette.mode === 'dark' ? '#fff' : '#000';

    const lightIconColor =
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';

    const togglePlayPause = useCallback(() => {
        if (!isPlaying) {
            onPlay();
        } else {
            onPause();
        }
        setPaused(!paused);
    }, [isPlaying]);

    const handlePrevious = useCallback(() => {
        if (trackIndex === 0) {
            const lastTrackIndex = tracks.length - 1;
            onTrackChange(lastTrackIndex);
        } else {
            onTrackChange(trackIndex - 1);
        }
    }, [trackIndex]);

    const currentTrack = useMemo(() => {
        return tracks[trackIndex];
    }, [tracks, trackIndex]);

    const handleNext = useCallback(() => {
        if (trackIndex >= tracks.length - 1) {
            onTrackChange(0);
        } else {
            onTrackChange(trackIndex + 1);
        }
    }, [trackIndex]);

    const handleSliderVolumeChange = (event: Event, newValue: number | number[]) => {
        onVolumeChange(newValue as number);
    };

    const onTimeUpdate = useCallback((e: React.UIEvent<HTMLAudioElement>) => {
        const currentTime = e.currentTarget.currentTime;
        setPos(() => currentTime);
    }, []);

    const onLoadedMetadata = useCallback(() => {
        const seconds = audioRef?.current?.duration;
        onDurationChange(seconds ?? 0);
    }, []);

    const repeat = useCallback(() => {
        if (position) onPositionChange(position);
        playAnimationRef.current = requestAnimationFrame(repeat);
    }, [position]);

    useEffect(() => {
        setPaused(!isPlaying);
    }, [duration, isPlaying]);

    useEffect(() => {
        if (isPlaying) {
            audioRef?.current?.play();
            playAnimationRef.current = requestAnimationFrame(repeat);
        } else {
            audioRef?.current?.pause();
            if (playAnimationRef && playAnimationRef.current) cancelAnimationFrame(playAnimationRef.current);
        }
    }, [repeat, isPlaying, currentTrack]);

    useEffect(() => {
        if (audioRef && audioRef.current) {
            audioRef.current.volume = volume / 100;
        }
    }, [volume]);

    useEffect(() => {
        if (audioRef?.current) audioRef.current.currentTime = position as number;
    }, [position]);

    return (
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
            <Widget className='mode'>
                <MetadataContainer>
                    <CoverImage>
                        {currentTrack?.thumbnail ? (
                            <img src={currentTrack.thumbnail} alt="audio avatar" />
                        ) : (
                            <div className="icon-wrapper">
                                <span className="audio-icon">
                                    <AudiotrackIcon />
                                </span>
                            </div>
                        )}
                    </CoverImage>
                    <Box mx={2} sx={{ display: 'flex', flexDirection: 'column', flex: 5 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ ml: 1.5, minWidth: 0 }}>
                                <Typography variant="caption" color="text.secondary" fontWeight={500}>
                                    {currentTrack?.author}
                                </Typography>
                                <Typography noWrap letterSpacing={-0.25}>
                                    {currentTrack?.title}
                                </Typography>
                                {currentTrack?.src && <audio
                                    src={currentTrack?.src}
                                    ref={audioRef}
                                    onLoadedMetadata={onLoadedMetadata}
                                    onEnded={onTrackEnd}
                                    onTimeUpdate={onTimeUpdate}
                                />}
                            </Box>
                        </Box>
                    </Box>
                </MetadataContainer>
                <ControlsContainer>
                    <ControlButtonsContainer>
                        <IconButton aria-label="previous song" onClick={handlePrevious}>
                            <SkipPreviousIcon sx={{ fontSize: "2rem" }} htmlColor={mainIconColor} />
                        </IconButton>
                        <IconButton
                            aria-label={paused ? 'play' : 'pause'}
                            onClick={togglePlayPause}
                        >
                            {paused ? (
                                <PlayArrowRounded
                                    sx={{ fontSize: '3rem' }}
                                    htmlColor={mainIconColor}
                                />
                            ) : (
                                <PauseRounded sx={{ fontSize: '3rem' }} htmlColor={mainIconColor} />
                            )}
                        </IconButton>
                        <IconButton aria-label="next song" onClick={handleNext}>
                            <SkipNextIcon sx={{ fontSize: "2rem" }} htmlColor={mainIconColor} />
                        </IconButton>
                    </ControlButtonsContainer>
                    <SliderContainer>
                        <Box
                            mx={2}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '8%'
                            }}
                        >
                            <TinyText>{DateUtils.formatDuration(pos)}</TinyText>
                        </Box>
                        <Slider
                            aria-label="time-indicator"
                            size="small"
                            value={pos}
                            min={0}
                            step={1}
                            max={duration}
                            onChange={(_, value) => {
                                onPositionChange(value as number);
                            }}
                            onChangeCommitted={(_, value) => {
                                onPositionChange(value as number);
                            }}
                            sx={{
                                color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
                                height: 4,
                                '& .MuiSlider-thumb': {
                                    width: 8,
                                    height: 8,
                                    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                                    '&:before': {
                                        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                                    },
                                    '&:hover, &.Mui-focusVisible': {
                                        boxShadow: `0px 0px 0px 8px ${theme.palette.mode === 'dark'
                                            ? 'rgb(255 255 255 / 16%)'
                                            : 'rgb(0 0 0 / 16%)'
                                            }`,
                                    },
                                    '&.Mui-active': {
                                        width: 20,
                                        height: 20,
                                    },
                                },
                                '& .MuiSlider-rail': {
                                    opacity: 0.28,
                                },
                            }}
                        />

                        <Box
                            mx={2}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '8%'
                            }}
                        >
                            <TinyText>{DateUtils.formatDuration(duration)}</TinyText>
                        </Box>
                    </SliderContainer>
                </ControlsContainer>
                <VolumeContainer>
                    <Stack mx={2} spacing={2} direction="row" sx={{ mb: 1, px: 1, width: '100%', maxWidth: "200px" }} alignItems="center">
                        <VolumeDownRounded htmlColor={lightIconColor} />
                        <Slider
                            aria-label="Volume"
                            size='small'
                            value={volume}
                            onChange={handleSliderVolumeChange}
                            min={0}
                            max={100}
                            sx={{
                                color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
                                height: 4,
                                '& .MuiSlider-thumb': {
                                    width: 8,
                                    height: 8,
                                    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                                    '&:before': {
                                        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                                    },
                                    '&:hover, &.Mui-focusVisible': {
                                        boxShadow: `0px 0px 0px 8px ${theme.palette.mode === 'dark'
                                            ? 'rgb(255 255 255 / 16%)'
                                            : 'rgb(0 0 0 / 16%)'
                                            }`,
                                    },
                                    '&.Mui-active': {
                                        width: 20,
                                        height: 20,
                                    },
                                },
                                '& .MuiSlider-rail': {
                                    opacity: 0.28,
                                },
                            }}
                        />
                        <VolumeUpRounded htmlColor={lightIconColor} />
                    </Stack>
                </VolumeContainer>
            </Widget>
        </Box>
    );
}

export default MusicPlayer;