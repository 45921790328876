import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as dayjs from 'dayjs';
import 'dayjs/locale/en';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { lazy } from 'react';
import './App.css';
import { PlayerContainer } from './components/player/PlayerContainer';
import { PlayerProvider } from './contexts/PlayerContext';
import { getTheme } from './styles';
import { AxiosSecureProvider } from './utils/axiosSecureProvider';

const Layout = lazy(() => import('./pages/Layout'));
const GlobalSnackbar = lazy(() => import('./components/common/GlobalSnackbar'));
dayjs.extend(relativeTime);
dayjs.locale('en');

const App: React.FC = () => {
  const theme = getTheme();
  console.log(process.env.NODE_ENV);
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AxiosSecureProvider>
          {/* <Suspense fallback={<span>Loading...</span>}> */}
          <GlobalSnackbar>
            <PlayerProvider>
              <PlayerContainer>
                <Layout />
              </PlayerContainer>
            </PlayerProvider>
          </GlobalSnackbar>
          {/* </Suspense> */}
        </AxiosSecureProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
